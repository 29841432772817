import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import Selectors from '../../Selectors';
import { withPage } from '../../Page';
import * as L from '../../Utils/Lang';
import PathPrefix from '../../Utils/PathPrefixUtil';
import Link from '../../Components/Link';
import * as Widget from '../../Components/Widget';

class ProductListingPage extends React.Component {
  render() {
    let {products} = this.props;

    return (
      <Wrapper>
        <Banner>
          <Widget.BgImage
            extraCss="display: flex; flex-direction: column; align-items: center; position: relative;"
            image={PathPrefix('/images/pages/products/banner.png')}
          >
            <Title>Products</Title>
            <SmallTitle>Prepaid SIM for Worldwide</SmallTitle>
          </Widget.BgImage>
        </Banner>

        {this._renderProductSection(products)}
      </Wrapper>
    );
  }

  _renderProductSection = products => (
    <ProductionSection>
      <h1>Product</h1>
      <div>{products.map(p => <ProductItem key={p.id} item={p} />)}</div>
    </ProductionSection>
  );
}

const Wrapper = styled.div``;
const Title = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 600;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const SmallTitle = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 200;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
  position: absolute;
  bottom: 5px;
`;

const Banner = styled.div`
  height: 400px;
`;

const ProductionSection = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 20px;

  & > h1 {
    margin-bottom: 10px;
    padding-left: 10px;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    padding: 40px 10px;
  }
`;

class ProductItem extends React.Component {
  render() {
    let { item } = this.props;

    return (
      <ProductItem.Wrapper to={`/product/${item.id}`}>
        <div>
          <Widget.BgImage
            extraCss={`
                background-image:
                    linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
                    url(${item.image});
            `}
          />
        </div>
        <p>{L.d(item, 'display_name')}</p>
      </ProductItem.Wrapper>
    );
  }

  static Wrapper = styled(Link)`
    position: relative;
    margin: 10px;
    width: calc(25% - 20px);
    padding-top: calc(14% - 20px);

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    & > p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      color: white;
      z-index: 1;
    }

    @media screen and (max-width: 900px) {
      width: calc(33% - 20px);
      padding-top: calc(18.56% - 20px);
    }

    @media screen and (max-width: 600px) {
      width: calc(50% - 20px);
      padding-top: calc(28.125% - 20px);
    }
  `;
}

export default withPage(connect(
  (state, ownProps) => ({
    products: Selectors.getProductList(state),
  }), null
)(ProductListingPage));
